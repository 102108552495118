

/* ----------------------------------------
    Imports
---------------------------------------- */

import { Component, Vue } from 'vue-property-decorator';
import $ from 'jquery';
import slick from 'slick-carousel';
import uniqid from 'uniqid';
import ArrowBackSvg from '@svg/ArrowBack.vue';

/* ----------------------------------------
    Component
---------------------------------------- */

@Component({
    props: ['data'],
    data() {
        return {
            uniqueId: uniqid(),
            isHidden: true
        };
    },
    methods: {
        slide() {
            /* eslint-disable */
            slick;
            $('.slider_' + this.uniqueId).slick({
                infinite: false,
                slidesToShow: 4,
                arrows: true,
                dots: false,
                slidesToScroll: 1,
                prevArrow: $(`.prev_${this.uniqueId}`),
                nextArrow: $(`.next_${this.uniqueId}`),
                autoplay: false,
                variableWidth: false,
                adaptiveHeight: true,
                responsive: [
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 1.1,
                            centerMode: false,
                            slidesToScroll: 1
                        }
                    },
                    {
                        breakpoint: 960,
                        settings: {
                            slidesToShow: 2.1,
                            centerMode: false,
                            slidesToScroll: 1
                        }
                    },
                    {
                        breakpoint: 1100,
                        settings: {
                            slidesToShow: 3.1,
                            centerMode: false,
                            slidesToScroll: 1
                        }
                    }
                ]
            });
        }
    },
    mounted() {
        let $this = this;
        setTimeout(() => {
            this.slide();
            this.isHidden = false;
        }, 700);
    }
})

/* ----------------------------------------
    Export
---------------------------------------- */

export default class InstagramBlock extends Vue { };

