

/* ----------------------------------------
    Imports
---------------------------------------- */

import { Component, Vue } from 'vue-property-decorator';
import ExternLink from '@svg/ExternLink';

/* ----------------------------------------
    Component
---------------------------------------- */

@Component({
    props: ['data'],
    components: {
        ExternLink
    },
    mounted() {
        this.setupScrollListener();
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        setupScrollListener() {
            window.addEventListener('scroll', this.handleScroll);
        },
        handleScroll() {
            const path = document.querySelector('.vacancies__wave');
            const scrollTop = window.scrollY;
            const docHeight = document.documentElement.scrollHeight - window.innerHeight;
            const scrollPercent = Math.min(scrollTop / docHeight, 1);
            const translateY = scrollPercent * 400;
            path.style.transform = `translateY(-${translateY}px)`;
        }
    }
})

/* ----------------------------------------
    Export
---------------------------------------- */

export default class VacancyBlock extends Vue { };

